import React, { useEffect } from "react"

import Layout from "../components/layout"

const freedbackForm = `<!-- Begin Freedback Form -->
<!-- DO NOT EDIT YOUR FORM HERE, PLEASE LOG IN AND EDIT AT FREEDBACK.COM -->
<form enctype="multipart/form-data" method="post" action="https://www.freedback.com/mail.php" accept-charset="UTF-8">
	<div>
		<input type="hidden" name="acctid" id="acctid" value="00c6re17wtu4dza9" />
		<input type="hidden" name="formid" id="formid" value="2005632" />
		<input type="hidden" name="required_vars" id="required_vars" value="field-139ab161f51f15f,email" />
	</div>
	<table cellspacing="5" cellpadding="5" border="0">
		<tr>
			<td valign="top">
				<strong>Your Name:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-139ab161f51f15f" id="field-139ab161f51f15f" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>Your Email Address:</strong>
			</td>
			<td valign="top">
				<input type="text" name="email" id="email" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>1. I am applying to register my child for the Vacational Studies Course at The Mary Hare School for:</strong>
			</td>
			<td valign="top">
				<select name="field-9e1d2c7d24a959a" id="field-9e1d2c7d24a959a">
					<option value="Full Course (13 July to 9 August 2025)">Full Course (13 July to 9 August 2025)</option>
					<option value="2 or 3 Weeks Course (between 13 July and 9 August 2025)">2 or 3 Weeks Course (between 13 July and 9 August 2025)</option>
				</select>
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>If you did not select the Full Course option above, please state the preferred dates (starting on a Sunday and finishing on a Saturday) within the period 13 July to 9 August 2025:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-fd0387a493b199b" id="field-fd0387a493b199b" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>My child would like to be considered for the following role/s (Full Course only):</strong>
			</td>
			<td valign="top">
				<input type="checkbox" name="field-9c10760a9e1fe04[]" id="field-9c10760a9e1fe04_0" value="Mentor (all ages)" /> Mentor (all ages)<br/>
				<input type="checkbox" name="field-9c10760a9e1fe04[]" id="field-9c10760a9e1fe04_1" value="Councillor (all ages)" /> Councillor (all ages)<br/>
				<input type="checkbox" name="field-9c10760a9e1fe04[]" id="field-9c10760a9e1fe04_2" value="House Captain (16+ year-olds)" /> House Captain (16+ year-olds)<br/>
				<input type="checkbox" name="field-9c10760a9e1fe04[]" id="field-9c10760a9e1fe04_3" value="Prefect/Senior Prefect (17+ year-olds - teaching hours will be reduced)" /> Prefect/Senior Prefect (17+ year-olds - teaching hours will be reduced)<br/>
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>Child&#39;s full name (as it should appear on our lists):</strong>
			</td>
			<td valign="top">
				<input type="text" name="name" id="name" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>Given name we should call him/her:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-4bfac74b0c170ce" id="field-4bfac74b0c170ce" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>Child&#39;s mobile phone number:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-1752604c9a1b48c" id="field-1752604c9a1b48c" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>My child has attended Vacational Studies previously and all details are as per my previous application (Please check at least one)</strong>
			</td>
			<td valign="top">
				<input type="radio" name="field-231dba53ce5e39d" id="field-231dba53ce5e39d_0" value="Yes (Go to section 15 (&#39;Optional Visits&#39;) or simply fill in any details that may have changed.)" /> Yes (Go to section 15 (&#39;Optional Visits&#39;) or simply fill in any details that may have changed.)<br/>
				<input type="radio" name="field-231dba53ce5e39d" id="field-231dba53ce5e39d_1" value="No" /> No<br/>
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>3. Gender:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-892e84d12197320" id="field-892e84d12197320" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>4. Date of birth:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-d672314bbf882c0" id="field-d672314bbf882c0" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>5. Nationality:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-329253958e67acc" id="field-329253958e67acc" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>6. Name and postal address of Parent or Guardian:</strong>
			</td>
			<td valign="top">
				<textarea name="field-081ca848fb37247" id="field-081ca848fb37247" rows="6" cols="40"></textarea>
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>Phone:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-52389e357aa8708" id="field-52389e357aa8708" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>Mobile:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-4cbb69039bf0927" id="field-4cbb69039bf0927" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>Email:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-6a5e8655ca1defe" id="field-6a5e8655ca1defe" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td valign="top">
				<strong>Fax:</strong>
			</td>
			<td valign="top">
				<input type="text" name="field-2fb88b29f66ccac" id="field-2fb88b29f66ccac" size="40" value="" />
				
			</td>
		</tr>
		<tr>
			<td colspan="2" valign="top">
				<strong><p>E-mail us if you are at a different address during the Course.</p></strong>
			</td>
		</tr><tr>
		<td valign="top">
			<strong>7. Names and addresses of TWO English-speaking relatives or friends (outside immediate family) who may act on my behalf if I cannot be contacted: (You must answer this section)</strong>
		</td>
		<td valign="top">
			<textarea name="field-fe61865fa753f2e" id="field-fe61865fa753f2e" rows="6" cols="40"></textarea>
			
		</td>
	</tr>
	<tr>
		<td valign="top">
			<strong>Phones:</strong>
		</td>
		<td valign="top">
			<input type="text" name="field-ff4d329e0d2e881" id="field-ff4d329e0d2e881" size="40" value="" />
			
		</td>
	</tr>
	<tr>
		<td valign="top">
			<strong>Mobiles:</strong>
		</td>
		<td valign="top">
			<input type="text" name="field-67b198be0f8cdd6" id="field-67b198be0f8cdd6" size="40" value="" />
			
		</td>
	</tr>
	<tr>
		<td valign="top">
			<strong>Emails:</strong>
		</td>
		<td valign="top">
			<input type="text" name="field-af230dc316360b8" id="field-af230dc316360b8" size="40" value="" />
			
		</td>
	</tr>
	<tr>
		<td valign="top">
			<strong>8. Religion (if any):</strong>
		</td>
		<td valign="top">
			<input type="text" name="field-85c02993b502a75" id="field-85c02993b502a75" size="40" value="" />
			
		</td>
	</tr>
	<tr>
		<td colspan="2" valign="top">
			<strong><p>Please e-mail vacstuds@vacstuds.com if you wish us to arrange for your child to attend a religious service while with us.</p></strong>
		</td>
	</tr><tr>
	<td valign="top">
		<strong>9. Assessment of his/her knowledge of English:</strong>
	</td>
	<td valign="top">
		<input type="radio" name="field-1cf1e420cde4617" id="field-1cf1e420cde4617_0" value="Good" /> Good<br/>
		<input type="radio" name="field-1cf1e420cde4617" id="field-1cf1e420cde4617_1" value="Fair" /> Fair<br/>
		<input type="radio" name="field-1cf1e420cde4617" id="field-1cf1e420cde4617_2" value="Poor" /> Poor<br/>
		<input type="radio" name="field-1cf1e420cde4617" id="field-1cf1e420cde4617_3" value="None" /> None<br/>
		
	</td>
</tr>
<tr>
	<td valign="top">
		<strong>10. Number of years studying English: (by July 2025)</strong>
	</td>
	<td valign="top">
		<input type="text" name="field-6a245b54af98706" id="field-6a245b54af98706" size="40" value="" />
		
	</td>
</tr>
<tr>
	<td colspan="2" valign="top">
		<strong><p>NEW STUDENTS ONLY: Please send a letter of recommendation from the school simply stating 'X is well-behaved&nbsp;and has&nbsp;a positive attitude towards studying'. This can be sent later and can be e-mailed.</p></strong>
	</td>
</tr><tr>
<td valign="top">
	<strong>11. If you wish to pay pocket money and trips in advance and need an invoice, state the amount here and specify &pound; or &euro;. Money remaining will be returned with your child in &pound; cash:</strong>
</td>
<td valign="top">
	<input type="text" name="field-a40a19354f3401c" id="field-a40a19354f3401c" size="40" value="" />
	
</td>
</tr>
<tr>
	<td valign="top">
		<strong>12. Our acceptance is on the understanding that your child is in good physical and mental health and is able to function independently in a multinational community and that your country&#39;s vaccinations programme has been followed.
		
		Please disclose here any past/present health problems, allergies or behavioural factors of which we should be aware before acceptance and if regular medication is taken:</strong>
	</td>
	<td valign="top">
		<textarea name="field-cb6d3db23e10078" id="field-cb6d3db23e10078" rows="6" cols="40"></textarea>
		
	</td>
</tr>
<tr>
	<td colspan="2" valign="top">
		<strong><p>In the case of food allergies, the student must come with a 1ml. Adrenalin (Epinephine) 1:1000 injection pen &shy; an &lsquo;EpiPen&rsquo;. Students from EU and EEA countries should bring a EHIC card (European Health Insurance Card) to ensure free NHS medical treatment for all conditions.</p></strong>
	</td>
</tr><tr>
<td valign="top">
	<strong>13. Brief description of his/her character and interests, if not already known by us:</strong>
</td>
<td valign="top">
	<textarea name="field-209fa71e8c1454d" id="field-209fa71e8c1454d" rows="6" cols="40"></textarea>
	
</td>
</tr>
<tr>
	<td valign="top">
		<strong>14. Previous longest time away from home (in days):</strong>
	</td>
	<td valign="top">
		<input type="text" name="field-2c93d1921873e7a" id="field-2c93d1921873e7a" size="40" value="" />
		
	</td>
</tr>
<tr>
	<td colspan="2" valign="top">
		<strong><p><strong>15.&nbsp;OPTIONAL VISITS - Tickets allocated to those who apply for the option first. Cost payable from pocket money.&nbsp; ALL TRIPS ARE OPTIONAL EXTRAS. <em>(These options are provisional and may be changed. If they are, parents will be e-mailed.)</em></strong></p></strong>
	</td>
</tr><tr>
<td valign="top">
	<strong>I would like to apply my son/daughter for the following optional extras:</strong>
</td>
<td valign="top">
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_0" value="TRIP: Windsor: Sunday 20.07.25. (Seeing the colleges and museums and/or off to shop with our staff): &pound;50.00" /> TRIP: Windsor: Sunday 20.07.25. (Seeing the colleges and museums and/or off to shop with our staff): &pound;50.00<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_1" value="TRIP: NEWBURY: Sunday 03.08.25. (Shopping/exploring in our local town. The bus leaves at 10.00 and is back for lunch at 13.00): &pound;20.00" /> TRIP: NEWBURY: Sunday 03.08.25. (Shopping/exploring in our local town. The bus leaves at 10.00 and is back for lunch at 13.00): &pound;20.00<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_2" value="TRIP: &#39;DOWNTON ABBEY&#39;: Monday 21.07.25. (Where the TV series was filmed): &pound;55.00" /> TRIP: &#39;DOWNTON ABBEY&#39;: Monday 21.07.25. (Where the TV series was filmed): &pound;55.00<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_3" value="TRIP: OPTIONAL: Wednesday 23.07.25. (To be announced March 2025)" /> TRIP: OPTIONAL: Wednesday 23.07.25. (To be announced March 2025)<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_4" value="TRIP: PARLIAMENT: Friday 25.07.25. (Inside the Houses of Parliament): &pound;60.00" /> TRIP: PARLIAMENT: Friday 25.07.25. (Inside the Houses of Parliament): &pound;60.00<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_5" value="TRIP: LONDON: Thursday 31.07.25. (In groups with our staff for exploration/shopping): &pound;55.00" /> TRIP: LONDON: Thursday 31.07.25. (In groups with our staff for exploration/shopping): &pound;55.00<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_6" value="TRIP: &#39;HARRY POTTER WORLD&#39;: Friday 01.08.25. (Where the films were made) 31 tickets only and cannot be chosen with Stonehenge: &pound;75.00" /> TRIP: &#39;HARRY POTTER WORLD&#39;: Friday 01.08.25. (Where the films were made) 31 tickets only and cannot be chosen with Stonehenge: &pound;75.00<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_7" value="OR TRIP: STONEHENGE: Friday 01.08.25. (5000 year-old stone circle) Cannot be chosen with &#39;Harry Potter&#39;: &pound;60.00" /> OR TRIP: STONEHENGE: Friday 01.08.25. (5000 year-old stone circle) Cannot be chosen with &#39;Harry Potter&#39;: &pound;60.00<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_8" value="ONE-TO-ONE TEACHING: (Individual lessons with our teachers): &pound;35 per one-hour session" /> ONE-TO-ONE TEACHING: (Individual lessons with our teachers): &pound;35 per one-hour session<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_9" value="THEATRE 1: Friday 18.07.25: (To be announced March 2025)" /> THEATRE 1: Friday 18.07.25: (To be announced March 2025)<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_10" value="THEATRE 2:  Wednesday 23.07.25: (To be announced March 2025)" /> THEATRE 2:  Wednesday 23.07.25: (To be announced March 2025)<br/>
	<input type="checkbox" name="field-fa443969eecd83f[]" id="field-fa443969eecd83f_11" value="EXAMINATION: TRINITY EXAMINATION IN SPOKEN ENGLISH: Wednesday 06.08.25: &pound;95-&pound;115.00 (depending on level). We decide on the level and make the appropriate deduction" /> EXAMINATION: TRINITY EXAMINATION IN SPOKEN ENGLISH: Wednesday 06.08.25: &pound;95-&pound;115.00 (depending on level). We decide on the level and make the appropriate deduction<br/>
	
</td>
</tr>
<tr>
	<td valign="top">
		<strong>16. He/she will</strong>
	</td>
	<td valign="top">
		<input type="checkbox" name="field-03df77e8f00f8f5[]" id="field-03df77e8f00f8f5_0" value="USE FREE HEATHROW COLLECTION SERVICE *" /> USE FREE HEATHROW COLLECTION SERVICE *<br/>
		<input type="checkbox" name="field-03df77e8f00f8f5[]" id="field-03df77e8f00f8f5_1" value="USE FREE HEATHROW RETURN SERVICE *" /> USE FREE HEATHROW RETURN SERVICE *<br/>
		<input type="checkbox" name="field-03df77e8f00f8f5[]" id="field-03df77e8f00f8f5_2" value="BE BROUGHT TO SCHOOL BY PARENT/GUARDIAN" /> BE BROUGHT TO SCHOOL BY PARENT/GUARDIAN<br/>
		<input type="checkbox" name="field-03df77e8f00f8f5[]" id="field-03df77e8f00f8f5_3" value="BE COLLECTED FROM SCHOOL BY PARENT/GUARDIAN" /> BE COLLECTED FROM SCHOOL BY PARENT/GUARDIAN<br/>
		<input type="checkbox" name="field-03df77e8f00f8f5[]" id="field-03df77e8f00f8f5_4" value="NEED SPECIAL AIRPORT COLLECTION ARRANGEMENTS *" /> NEED SPECIAL AIRPORT COLLECTION ARRANGEMENTS *<br/>
		<input type="checkbox" name="field-03df77e8f00f8f5[]" id="field-03df77e8f00f8f5_5" value="NEED SPECIAL AIRPORT RETURN ARRANGEMENTS *" /> NEED SPECIAL AIRPORT RETURN ARRANGEMENTS *<br/>
		
	</td>
</tr>
<tr>
	<td colspan="2" valign="top">
		<strong><p>* Our free service applies to flights arriving at Heathrow Terminals 2, 3, 4 and 5 from 12.00-16.00 on 13 July and flights leaving Heathrow Terminals 2, 3, 4 and 5 from 10.00-13.00 on 9 August. UM/Special Assistance tickets after 12.00 are excluded and will be by taxi. We shall request flight details after we have received Course Fees.</p></strong>
	</td>
</tr><tr>
<td colspan="2" valign="top">
	<strong><p><strong>PAYMENT OF COURSE FEES</strong></p></strong>
</td>
</tr><tr>
<td valign="top">
	<strong>17. If this application is accepted, when I receive your Invoice, I shall:</strong>
</td>
<td valign="top">
	<input type="radio" name="field-ec8b9eda1fb5ee7" id="field-ec8b9eda1fb5ee7_0" value="Send FULL PAYMENT in &pound; Sterling within 30 days from the invoice date" /> Send FULL PAYMENT in &pound; Sterling within 30 days from the invoice date<br/>
	<input type="radio" name="field-ec8b9eda1fb5ee7" id="field-ec8b9eda1fb5ee7_1" value="SEND 50% PAYMENT in &pound; Sterling within 30 days from the invoice date and the 50% BALANCE in &pound; Sterling by 15 May 2025 (Option unavailable after 01.04.25)" /> SEND 50% PAYMENT in &pound; Sterling within 30 days from the invoice date and the 50% BALANCE in &pound; Sterling by 15 May 2025 (Option unavailable after 01.04.25)<br/>
	<input type="radio" name="field-ec8b9eda1fb5ee7" id="field-ec8b9eda1fb5ee7_2" value="Send FULL PAYMENT in &euro; euro at today&#39;s &pound;:&euro; rate within 30 days from the invoice date" /> Send FULL PAYMENT in &euro; euro at today&#39;s &pound;:&euro; rate within 30 days from the invoice date<br/>
	<input type="radio" name="field-ec8b9eda1fb5ee7" id="field-ec8b9eda1fb5ee7_3" value="SEND 50% PAYMENT in &euro; euro at today&#39;s &pound;:&euro; rate within 30 days from the invoice date and the 50% BALANCE in &euro; euro by 15 May 2025 at the &pound;:&euro; rate on 1 May 2025 (Option unavailable after 01.04.25)" /> SEND 50% PAYMENT in &euro; euro at today&#39;s &pound;:&euro; rate within 30 days from the invoice date and the 50% BALANCE in &euro; euro by 15 May 2025 at the &pound;:&euro; rate on 1 May 2025 (Option unavailable after 01.04.25)<br/>
	<input type="radio" name="field-ec8b9eda1fb5ee7" id="field-ec8b9eda1fb5ee7_4" value="Send FULL PAYMENT in $ USD at today&#39;s &pound;:$ rate within 30 days from the invoice date" /> Send FULL PAYMENT in $ USD at today&#39;s &pound;:$ rate within 30 days from the invoice date<br/>
	<input type="radio" name="field-ec8b9eda1fb5ee7" id="field-ec8b9eda1fb5ee7_5" value="SEND 50% PAYMENT in $ USD at today&#39;s &pound;:$ rate within 30 days from the invoice date and the 50% BALANCE in $ USD by 15 May 2025 at the &pound;:$ rate on 1 May 2025 (Option unavailable after 01.04.25)" /> SEND 50% PAYMENT in $ USD at today&#39;s &pound;:$ rate within 30 days from the invoice date and the 50% BALANCE in $ USD by 15 May 2025 at the &pound;:$ rate on 1 May 2025 (Option unavailable after 01.04.25)<br/>
	
</td>
</tr>
<tr>
	<td valign="top">
		<strong>18. T-shirt size (must be completed): </strong>
	</td>
	<td valign="top">
		<input type="radio" name="field-4f3f3049b1cb9a8" id="field-4f3f3049b1cb9a8_0" value="S" /> S<br/>
		<input type="radio" name="field-4f3f3049b1cb9a8" id="field-4f3f3049b1cb9a8_1" value="M" /> M<br/>
		<input type="radio" name="field-4f3f3049b1cb9a8" id="field-4f3f3049b1cb9a8_2" value="L" /> L<br/>
		<input type="radio" name="field-4f3f3049b1cb9a8" id="field-4f3f3049b1cb9a8_3" value="XL" /> XL<br/>
		
	</td>
</tr>
<tr>
	<td valign="top">
		<strong>I have read, understand and agree with the following:</strong>
	</td>
	<td valign="top">
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_0" value="I agree with the rules of the Course as set out in the &#39;Notes for Parents&#39; and &#39;Notes for Students&#39;." /> I agree with the rules of the Course as set out in the &#39;Notes for Parents&#39; and &#39;Notes for Students&#39;.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_1" value="I trust Vacational Studies to decide on the level of supervision required on trips and to permit visits and outings (if any) at its discretion." /> I trust Vacational Studies to decide on the level of supervision required on trips and to permit visits and outings (if any) at its discretion.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_2" value="If neither the undersigned nor the person in section 7 can be reached in an emergency, I consent to medical treatment being authorised by Ian G. Mucklejohn of Vacational Studies." /> If neither the undersigned nor the person in section 7 can be reached in an emergency, I consent to medical treatment being authorised by Ian G. Mucklejohn of Vacational Studies.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_3" value="I understand that smoking or possessing any smoking material or narcotic is forbidden and will result in immediate expulsion and removal from the Course by me within 72 hours." /> I understand that smoking or possessing any smoking material or narcotic is forbidden and will result in immediate expulsion and removal from the Course by me within 72 hours.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_4" value="I understand that if for any reason not covered by our Insurance policy a student is withdrawn from the Course less than sixty days before it begins, or fails to attend the Course, or leaves before it ends, no refund of Fees will be given." /> I understand that if for any reason not covered by our Insurance policy a student is withdrawn from the Course less than sixty days before it begins, or fails to attend the Course, or leaves before it ends, no refund of Fees will be given.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_5" value="I understand that any subsequent change in length of stay means cancellation of the original stay and re-acceptance (if possible) for the revised duration will be at the Fee applicable at the time of re-acceptance." /> I understand that any subsequent change in length of stay means cancellation of the original stay and re-acceptance (if possible) for the revised duration will be at the Fee applicable at the time of re-acceptance.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_6" value="I understand that if any Fees remain unpaid thirty days after the date of the Invoice (unless otherwise stated on the Invoice), and if any Fees remain unpaid sixty days before the Course begins, acceptance of the student will be withdrawn." /> I understand that if any Fees remain unpaid thirty days after the date of the Invoice (unless otherwise stated on the Invoice), and if any Fees remain unpaid sixty days before the Course begins, acceptance of the student will be withdrawn.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_7" value="I understand that Insurance is only as described on the Insurance information sheet." /> I understand that Insurance is only as described on the Insurance information sheet.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_8" value="I accept the terms and agree to be bound by the conditions of the Insurance cover. I declare that my child is in good mental and physical health." /> I accept the terms and agree to be bound by the conditions of the Insurance cover. I declare that my child is in good mental and physical health.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_9" value="I and my child agree to my and my child&#39;s details being stored on Vacational Studies computer system in compliance with GDPR accessible on this site (they will not be passed on to a third-party) and accept that images of my child will appear on this site." /> I and my child agree to my and my child&#39;s details being stored on Vacational Studies computer system in compliance with GDPR accessible on this site (they will not be passed on to a third-party) and accept that images of my child will appear on this site.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_10" value="I agree that my details may be disclosed to other parents for coinciding travel. I agree that my child&#39;s details may be disclosed to other students for contact purposes. (If you wish to delete either or both sentences, contact us first)." /> I agree that my details may be disclosed to other parents for coinciding travel. I agree that my child&#39;s details may be disclosed to other students for contact purposes. (If you wish to delete either or both sentences, contact us first).<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_11" value="I agree to return the completed and signed &#39;Agreement and Travel Details&#39; form by 6 June 2025 and comply with the dates and times therein." /> I agree to return the completed and signed &#39;Agreement and Travel Details&#39; form by 6 June 2025 and comply with the dates and times therein.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_12" value="I understand that Vacational Studies contractual arrangement ends at 15.00 BST on 9 August 2025. Any flight departures later than this time must be arranged for 8 August." /> I understand that Vacational Studies contractual arrangement ends at 15.00 BST on 9 August 2025. Any flight departures later than this time must be arranged for 8 August.<br/>
		<input type="checkbox" name="field-ac6e58c5d2ae377[]" id="field-ac6e58c5d2ae377_13" value="I understand that English Law applies to all aspects of Vacational Studies." /> I understand that English Law applies to all aspects of Vacational Studies.<br/>
		
	</td>
</tr>
<tr>
	<td colspan="2" valign="top">
		<strong><p>Office address: Vacational Studies, Pepys&rsquo; Oak, Tydehams, Newbury, Berkshire RG14 6JT, England. Telephone: 07717 007 007; (01635) 523 333&nbsp; International: +44 7717 007 007; +44 1635 523 333 Fax: (01635) 523 999 International Fax: +44 1635 523 999</p></strong>
	</td>
</tr><tr>
<td colspan="2" valign="top">
	<strong><p>Bank address: National Westminster Bank, 30 Market Place, Newbury, Berkshire RG14 5AJ, England.</p></strong>
</td>
</tr><tr>
<td valign="top">
	<strong>A letter of recommendation may not be required if such a request might conflict with the policy of the school or government. Please state possible conflict.</strong>
</td>
<td valign="top">
	<textarea name="field-1d4f850e30ac946" id="field-1d4f850e30ac946" rows="6" cols="40"></textarea>
	
</td>
</tr>
<tr>
	<td valign="top">
		<strong>Do you need a support letter for a visa?</strong>
	</td>
	<td valign="top">
		<input type="radio" name="field-d8d29aa9d1eb16c" id="field-d8d29aa9d1eb16c_0" value="Yes" /> Yes<br/>
		<input type="radio" name="field-d8d29aa9d1eb16c" id="field-d8d29aa9d1eb16c_1" value="No" /> No<br/>
		
	</td>
</tr>
<tr>
	<td colspan="2" align="center">
		<input type="submit" value=" Submit Form " />
	</td>
</tr>
</table>
</form>
<!-- End Freedback Form -->
`

const ApplicationPage = ({ data }) => {
  function markupApplicationPageForm() {
    return { __html: freedbackForm }
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "/js/application.js"

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    let customSelect = document.createElement("script")
    customSelect.src = "/js/_1_custom-select.js" // 👈 make sure to use the correct path
    customSelect.id = "custom-select"
    document.body.appendChild(customSelect)

    return () => {
      document.body.removeChild(customSelect)
    }
  }, [])

  return (
    <Layout>
      <div className="padding-bottom-xxl position-relative z-index-1">
        <article className="article">
          <div className="container max-width-adaptive-md padding-top-xl margin-bottom-lg">
            <h1 className="text-xxxl text-center">Application Form 2025</h1>
          </div>
          <div
            className="freedback container max-width-adaptive-sm "
            dangerouslySetInnerHTML={markupApplicationPageForm()}
          />
        </article>
      </div>
    </Layout>
  )
}

export default ApplicationPage
